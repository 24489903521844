// 方案详情
<template>
  <div class="content">
    <div class="imgbox">
      <!-- <img src="../assets/img/lun1.png" alt="" /> -->
      <img src="../assets/img/product.png" alt="" />
      <div>
        <p class="tils" v-show="lang == '0'">{{solutions.tit}}</p>
        <p class="tils" v-show="lang == '1'">{{solutions.titEn}}</p>
        <p class="cont" v-show="lang == '0'">{{solutions.cont}}</p>
        <p class="cont" v-show="lang == '1'">{{solutions.contEn}}</p>
        <el-button v-show="lang == '0'" @click="$router.push('/contact')">咨询我们<i class="el-icon-right" style="font-size:14px"></i></el-button>
        <el-button v-show="lang == '1'" @click="$router.push('/contact')">Consult us<i class="el-icon-right" style="font-size:14px"></i></el-button>
      </div>
    </div>
    <div class="bai">
        <div class="main">
            <div class="title">
                <span></span>
                <span v-show="lang == '0'">方案介绍</span>
                <span v-show="lang == '1'">Scheme introduction</span>
            </div>
            <div class="box" v-for="(item,index) in sis" :key="index">
                <div class="tils" v-show="lang == '0'">{{item.tit}}</div>
                <div class="tils" v-show="lang == '1'">{{item.titEn}}</div>
                <p v-show="lang == '0'">{{item.cont}}</p>
                <p v-show="lang == '1'">{{item.contEn}}</p>
                <img :src="imgUrl+item.img" alt="">
            </div>
        </div>
    </div>
    <!-- <div class="main">
        <div class="box">
            <div class="tils">产品概述</div>
            <p>雨情自动遥测系统是基于GIS地图开发，能够在线显示、统计、上传降雨量的自动化遥测平台，实时采集降雨量数据，广泛应用于防汛、气象、城市排涝等工作。</p>
            <img src="../assets/img/u43.png" alt="">
        </div>
    </div> -->
    <div class="bai">
        <div class="main">
        <div class="title">
            <span></span>
            <span v-show="lang == '0'">客户案例</span>
            <span v-show="lang == '1'">Customer case</span>
        </div>
        <div class="box-data">
            <div class="data" v-for="(item,index) in sccs" :key="index">
               <div class="top">
                   <img :src="imgUrl + item.logo" alt="">
               </div>
               <div class="bot">
                   <div v-show="lang == '0'">{{item.industry}}</div>
                   <div v-show="lang == '1'">{{item.industryEn}}</div>
                   <p v-show="lang == '0'">{{item.cname}}</p>
                   <p v-show="lang == '1'">{{item.cnameEn}}</p>
               </div>
               <div class="hide">
                <div class="top">
                    <p v-show="lang == '0'">{{item.cont}}</p>
                    <p v-show="lang == '1'">{{item.contEn}}</p>
                </div>
                <div class="bot" v-show="lang == '0'">
                    <el-button size="mini"  v-for="(item,index) in label" :key="index">{{item}}</el-button>
                </div>
                <div class="bot" v-show="lang == '1'">
                  <el-button size="mini" v-for="(v,i) in labelEn" :key="i">{{v}}</el-button>
                </div>
            </div>
            </div>
        </div>
    </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imgUrl: "http://101.200.43.114:8710/jeecg-boot/sys/common/static/",
      lang: "",
      tit: "",
      titEn: "",
      cont: "",
      contEn: "",
      solutions: {}, //解决方案主表
      sis: [], //方案介绍
      sccs: [], //客户案例
      label: [],
      labelEn: [],
    };
  },
  mounted() {
    this.lang = localStorage.getItem("newv");
    this.programme();
  },
  methods: {
    programme() {
      this.$http
        .get("/jeecg-boot/xrcx/solutions/queryById?id=" + this.$route.query.id)
        .then((res) => {
          this.solutions = res.result.solutions;
          res.result.sis.forEach(v=>{
            if(v.isdisable == 0){
              this.sis.push(v)
            }
          })
          res.result.sccs.forEach(v=>{
            if(v.isdisable == 0){
              this.sccs.push(v)
            }
          })
          this.sccs.forEach((v) => {
            if(v.isdisable == 0){
              v.lable.split(",");
              this.label = v.lable.split(",");
              this.labelEn = v.lableEn.split(",");
            }
            
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  // height: 500px;
  .imgbox {
    width: 100%;
    height: 350px;
    position: relative;
    // margin-bottom: 50px;
    // background-image: url("../assets/img/lun3.png");
    div{
      width: 50%;
      height: 80%;
      // background-color: pink;
      position: absolute;
      left: 15%;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;
      .tils{
        font-size: 48px;
        color: #ffffffe5;
      }
      .cont{
        font-size: 14px;
        color: #ffffffe5;
      }
    }
    img{
      width: 100%;
      height: 100%;
    }
    button {
      width: 150px;
      height: 36px;
      color: #fff;
      background-color: rgba(255,255,255,0);
      border: 1px solid #fff;
    }
  }
  .bai {
    width: 100%;
    background-color: #fff;
    padding: 50px 0;
  }
  .main {
    width: 70%;
    // height: 640px;
    margin: 0 auto 50px;
    // background-color: #fff;
    .title {
      width: 100%;
      height: 65px;
      display: flex;
      align-items: center;
      span:nth-child(1) {
        display: inline-block;
        width: 8px;
        height: 26px;
        background-color: #409eff;
      }
      span:nth-child(2) {
        font-size: 20px;
        color: #666666;
        font-weight: 700;
        margin-left: 10px;
      }
      span:nth-child(3) {
        font-size: 20px;
        color: #666666;
        font-weight: 700;
        margin-left: 10px;
      }
    }
    .box {
      width: 100%;
      height: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-wrap: wrap;
      .tils {
        font-size: 36px;
        color: #333333;
        margin: 50px 0;
      }
      p {
        font-size: 16px;
        color: #7f7f7f;
        margin-bottom: 50px;
      }
      img {
        width: 100%;
        height: 580px;
      }
    }
    .box-data {
      width: 100%;
      height: 90%;
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 50px;

      .data:hover {
        visibility: hidden;
        .hide {
          visibility: visible;
        }
      }
      .data {
        width: 23%;
        height: 360px;
        background-color: #fff;
        box-shadow: 0px 0px 5px 5px #eee;
        position: relative;
        // top: 0;
        // left: 0;
        display: inline-block;
        margin: 0 26px 20px 0;

        .top {
          width: 100%;
          height: 40%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 90%;
            height: 60%;
          }
        }
        .bot {
          width: 100%;
          height: 60%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          //   background-color: pink;

          div {
            width: 90%;
            display: flex;
            justify-content: flex-start;
            font-size: 14px;
            color: #666;
            margin: 30px 0;
          }
          p {
            width: 90%;
            display: flex;
            justify-content: flex-start;
            font-size: 20px;
            color: #333;
          }
        }

        .hide {
          width: 100%;
          height: 360px;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          background-color: #2f9bfe;
          position: absolute;
          top: -20px;
          left: 0;
          visibility: hidden;
          .top {
            width: 86%;
            height: 50%;
            padding-top: 20px;
            font-size: 14px;
            color: #fff;
            line-height: 28px;
          }
          .bot {
            width: 86%;
            height: 40%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            button {
              background-color: #2f9bfe;
              font-size: 14px;
              color: #fff;
            }
          }
        }
      }
    }
    // .data:hover{
    //     .hide{
    //         display: inline-flex;
    //     }
    // }
  }
}
</style>